/**
 * Created by dafre on 5/12/2017.
 */

export class WorldVertex {
  public PositionBuffer: any[];
  public ColorBuffer: any[];
  public IndexBuffer: any[];
  public TextureCoordBuffer: any[];
  public NormalBuffer: any[];
  public TangentBuffer: any[];
  public BiTangentBuffer: any[];
}
